import React from 'react';
import "./MainText.css";

export default function MainText()
{
    return (
        <div className="row justify-content-md-center">
            <div className="col-8 fs-mainText">
                <p>Faint Signals is an invitation to slow down. An opportunity to experience an imagined Yorkshire forest, densely populated with sounds of nature from the British Library's archive. </p>
                <p>Faint Signals is an ambient and slow experience of discovery, exploring the world one step at a time. With thousands of combinations of sounds, your constantly evolving experience will be unique to you.</p>
                <p></p>
                <div className="mobileInstruction">
                    <p><b>Use the four arrow keys to navigate.</b></p>
                    <ul>
                        <li>Forwards to move forwards ↑</li>
                        <li>Backwards to move backwards ↓</li>
                        <li>Left to turn to the left ←</li>
                        <li>Right to turn to the right →</li>
                    </ul>
                </div>
                <p>You are invited to explore the woodland changing through all four seasons as the experience evolves from day to night. Each tile you move across unlocks narrative and data on the landscape, all of which are based on real life environments, with wildlife, weather and other nature sounds reflecting the diversity and complexity of ecosystems in the region. A rich variety of sounds can be unlocked by discovering particles of light lingering in the forest.</p>
                <p>Faint Signals is available through modern browsers (Firefox and Chrome) but is not currently optimised for mobile devices. Please note it contains flashing images.</p>
                <div className="mobileInstruction">
                    <p>The full experience lasts about 30mins, after which you can choose to continue into the next year or refresh your browser to generate a new landscape.</p>
                    <a href="https://faintsignals.io/live/" target="_blank">
                        <p><b>Wear headphones and click here to begin.</b></p>
                    </a>
                    <div className="fs-survey-text text-right">
                        <a href="https://wh1.snapsurveys.com/s.asp?k=160214890399" target="_blank">
                            <b>Please leave your feedback here.</b>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}