import React from "react";
import "./Instructions.css";

export default function Instructions()
{
    return (
        <div>
            <div className="fs-sorryContent">
                <div className="mobileMsg text-center">
                    <p>Sorry, Faint Signals is not mobile-friendly, please try loading the site on a computer.</p>
                </div>
            </div>
        </div>
    )
}