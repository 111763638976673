import React from 'react';
import FsNavbar from './components/FsNavbar';
import MainText from "./components/MainText";
import './App.css';
import FsFooter from "./components/FsFooter";
import Instructions from "./components/Instructions";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Particles from 'react-particles-js';
import CookieConsent from "react-cookie-consent";

function App() {
    Analytics();
    return (
    <div className="App">
        <CookieConsent
            location="bottom"
            buttonText="Yes, I agree!"
            cookieName="collectCookies"
            style={{ background: "rgb(0,0,0)" }}
            buttonStyle={{ background: "rgba(20,20,20,0.8)", "border-radius": "3px", color: "rgba(256,256,256,0.6)", fontSize: "15px" }}
            expires={150}>
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <Particles
            id="fs-main"
            style={{width: '1800px',height: '100vh',}}
            params={{
                "particles": {
                    "number": {
                        "value": 20,
                        "density": {
                            "enable": true,
                            "value_area": 800
                        }
                    },
                    "color": {
                        "value": "#ffffff"
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                    },
                    "opacity": {
                        "value": 0.2,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 1,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": false,
                    },
                    "move": {
                        "enable": true,
                        "speed": 1,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                        },
                        "onclick": {
                            "enable": false,
                        },
                        "resize": true
                    },
                },
                "retina_detect": true
            }}
        />
        <div className="container-fluid">
            <FsNavbar/>
        </div>
        <div className="container">
              <MainText/>
        </div>
        <div className="container-fluid">
            <Instructions/>
            <hr className="line"/>
            <FsFooter/>
        </div>
    </div>
    );
}

function Analytics(){
    const trackingId = "UA-181042381-1";
    ReactGA.initialize(trackingId);
    const history = createBrowserHistory();

    // Initialize google analytics page view tracking
    history.listen(location => {
        ReactGA.set({ page: window.location.pathname }); // Update the user's current page
        ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    });
}

export default App;