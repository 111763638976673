import React from 'react';
import "./FsNavbar.css";

import {
    Navbar,
    NavbarBrand,
} from 'reactstrap';

export default function FsNavbar()
{

    return (
    <div>
        <Navbar className="navbar row fb-transparent"  expand="md">
            <div className="col">
            <NavbarBrand className="fs-logo" href="/">
                Faint Signals
            </NavbarBrand>
            </div>
            <div className="credit-icons col">
                <a href="https://www.bl.uk/" target="_blank" className="nav-icon">
                    <img src="./res/img/BL_Logo_White.png"/>
                </a>
                <a href="https://invisibleflock.com/" target="_blank" className="nav-icon">
                    <img src="./res/img/iF_logo_WHITE.png"/>
                </a>
            </div>
        </Navbar>
    </div>
    );
}
