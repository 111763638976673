import React from 'react';
import "./FsFooter.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";

export default function MainText()
{
    return (
        <div>
            <div className="row version">
                <div className="row col-version">
                    <div className="col-md-2 col-sm-1">
                        <a href="https://invisibleflock.com/" target="_blank" className="icon">
                            <img src="./res/img/iF_logo_WHITE.png"/>
                        </a>
                    </div>
                    <div className="fs-creditsText col-md-3 col-sm-1 pl-md-5">
                        <p>Invisible Flock are supported by Arts Council England as a National Portfolio Organisation.</p>
                    </div>
                </div>
                <div className="row col-version-bl">
                    <div className="fs-creditsText col-md-5 col-sm-1">
                        <p>Faint Signals is a commission by the British Library.</p>
                    </div>
                    <div className="col-md-2 col-sm-1">
                        <a href="https://www.bl.uk/" target="_blank" className="icon">
                            <img src="./res/img/BL_Logo_White.png"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row mobileInstruction fs-icon-container offset-1">
                <div className="col-2">
                    <a href="https://instagram.com/invisibleflock" target="_blank">
                        <FontAwesomeIcon className="fs-icons" icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com/InvisibleFlock" target="_blank">
                        <FontAwesomeIcon className="fs-icons" icon={faFacebook} />
                    </a>
                    <a href="https://twitter.com/invisibleflock" target="_blank">
                        <FontAwesomeIcon className="fs-icons" icon={faTwitter} />
                    </a>
                </div>
                <div className="col-2 fs-socials">
                    <a href="https://www.instagram.com/britishlibrary/" target="_blank">
                        <FontAwesomeIcon className="fs-icons" icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com/britishlibrary" target="_blank">
                        <FontAwesomeIcon className="fs-icons" icon={faFacebook} />
                    </a>
                    <a href="https://twitter.com/britishlibrary" target="_blank">
                        <FontAwesomeIcon className="fs-icons" icon={faTwitter} />
                    </a>
                </div>
            </div>
            <p className="fs-creditsText text-center pt-3">Invisible Flock would like to acknowledge support from the following people, Cheryl Tipp, Curator of Wildlife & Environmental Sounds at the British Library; Claire Booth-Kurpnieks, Researcher and Tereza Dankova, Web Developer.</p>
        </div>
    )
}